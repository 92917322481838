
// import echarts from 'echarts'
import * as echarts from 'echarts';
var data = [220, 182];
var barWidth = 15;
var maxNum = 0;
for (var i = 0; i < data.length; i++) {
  if (data[i] > maxNum) {
    maxNum = data[i];
  }
}

// 横向柱状图
export let option1 = {
  color: ['#FFB74D', '#FFF176', '#AED581', '#4DB6AC', '#4FC3F7'],
  title: {
  },
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'shadow'
    }
  },
  grid: {
    top: '5%',
    left: '10%',
    right: '20%',
    bottom: '0%',
    containLabel: true
  },
  xAxis: {
    type: 'value',
    show: false,
    axisLine: {
      show: true,
    },
    axisTick: {
      show: true
    },
    axisLabel: {
      show: true
    },
    splitLine: {
      show: true,
      lineStyle: {
        type: 'dashed',
        color: 'rgba(255, 255, 255, 1)',
        opacity: '0.1',
      },

    },
    data: []
  },
  yAxis: {
    type: 'category',
    axisLabel: {
      color: '#fff',
      textStyle: {
        fontSize: 12,
      },
      formatter: function (value) {
        return value.split('').join('\n')
      }
    },
    splitLine: {
      show: false,
    },
    axisLine: {
      show: false,
    },

    axisTick: {
      show: false
    },
    data: []
  },
  series: [
    {
      name: '',
      type: 'bar',
      data: [],
      barWidth: 14,
      // barGap: 5,
      label: {   //设置柱状图上显示  对应值
        show: true,
        formatter: '{c}',
        position: 'right',
        color: 'rgba(255, 255, 255, 0.5)',
        fontSize: '12px',
      },

      itemStyle: {
        color: function (params) {
          var colorList = ['#FFB74D', '#FFF176', '#AED581', '#4DB6AC', '#4FC3F7']
          return colorList[params.dataIndex]
        },
      },
    },

  ]
},

  pieoption = {
    title: {
      text: '',
      show: true,
      textStyle: {
        color: '#fff',
        x: 'center',
        fontSize: 18,
        fontWeight: 'normal'
      },
      // x:'center'
    },
    tooltip: {
      show: false,
      trigger: 'item',
      formatter: '{a} <br/>{b}: {c} ({d}%)'
    },
    color: ['#4fc3f7', '#fff176', '#ffb74d', '#aed581', '#4db6ac', '#7986cb', '#ba68c8', '#e57373'],
    legend: {
      orient: "vertical",
      show: false,
      itemGap: 10,
      itemWidth: 10,
      itemHeight: 10,
      right: '10',
      bottom: '0',
      textStyle: {
        color: '#fff',
        fontSize: 16,
      }
    },
    series: [
      {
        name: '',
        type: 'pie',
        radius: ['45%', '65%'],
        center: ["50%", "50%"],
        avoidLabelOverlap: false,
        labelLine: {
          //图形外文字线
          normal: {
            length: 20,
            length2: 60,
          },
        },
        label: {
          normal: {
            // formatter: '{b}:{c}: ({d}%)',
            formatter: '{d}%  \n {b}', //图形外文字上下显示
            borderWidth: 20,
            borderRadius: 4,
            padding: [0, -60], //文字和图的边距
            rich: {
              a: {
                fontSize: 12,
                lineHeight: 22,
              },
              b: {
                //name 文字样式
                fontSize: 12,
                lineHeight: 22,
              },
              c: {
                //value 文字样式
                fontSize: 12,
                lineHeight: 22,
                align: 'center',
              },
            },
          },
        },
        emphasis: {
          label: {
            show: true,
            fontSize: '16',
          }
        },
        data: []
      }
    ]
  },

  baroptin = {
    color: ['#FFB74D', '#FFF176', '#AED581', '#4DB6AC', '#4FC3F7'],
    xAxis: {
      type: 'category',
      data: ['60岁以上', '40-60岁', '26-40岁', '18-26岁', '18岁以下'],
      axisLabel: {
        interval: 0, // 坐标轴刻度标签的显示间隔
        textStyle: {
          color: '#fff'
        }
      },
      splitLine: {
        show: false,
        lineStyle: {
          type: 'dashed',
          color: '#FFFFFF',
        },
      },
      axisLine: {
        show: false, //隐藏y轴
      },
      axisTick: {
        show: false,  //刻度线
      },
    },
    yAxis: {
      type: 'value',
      name: '单位（人）',
      nameTextStyle: {
        color: "#aaa",
        nameLocation: "start",
      },
      splitLine: {
        show: true,
        lineStyle: {
          type: 'dashed',
          color: 'rgba(255, 255, 255, 1)',
          opacity: '0.1',
        },

      },
      axisLine: {
        show: false, //隐藏y轴
      },
      axisTick: {
        show: false,  //刻度线
      },
      axisLabel: {
        interval: 0, // 坐标轴刻度标签的显示间隔
        textStyle: {
          color: '#fff'
        }
      },
    },
    series: [
      {
        data: [],
        type: 'bar',
        barWidth: 20,
        label: {   //设置柱状图上显示  对应值
          show: true,
          formatter: '{c}',
          position: 'top',
          color: 'rgba(255, 255, 255, 0.4)',
          fontSize: '12px',
          fontWeight: '300',
        },
        itemStyle: {
          color: function (params) {
            var colorList = ['#FFB74D', '#FFF176', '#AED581', '#4DB6AC', '#4FC3F7']
            return colorList[params.dataIndex]
          },
        },
      },
    ],
  },

  poption = {
    grid: {
      top: '10%',
      left: '20%',
      right: '10%',
      bottom: '0%',
    },

    title: {
      text: '',
    },
    xAxis: {
      show: false,
    },
    yAxis: {
      data: ['男性', '女性'],
      splitLine: {
        show: false,
      },
      axisLabel: {
        // color: '#02afff' 
        textStyle: { fontSize: '80%', color: '#fff' },
      },
      axisLine: {
        show: false,
      },
      axisTick: false,
    },
    series: [
      {
        type: 'bar',
        barWidth: barWidth,
        zlevel: 2,
        data: [],
        label: {
          show: true,
          position: 'right',
          distance: 20,
          align: 'center',
          formatter: function (params) {
            console.log(params);
            var percent = Number((params.data.value / params.data.maxNum) * 100).toFixed(2) + '%';
            // var percent = '60%'
            return percent;
          },
          color: '#fff',
          fontSize: 12,
        },
        itemStyle: {
          //柱形图圆角，初始化效果
          barBorderRadius: [10],
          borderRadius: 10,
          color: new echarts.graphic.LinearGradient(0, 1, 1, 1, [
            { offset: 0, color: '#395CFE' },
            { offset: 1, color: '#2EC7CF' },
          ]),

        },
      },
      {
        type: 'bar',
        barWidth: barWidth,
        barGap: '-100%',
        data: [],
        itemStyle: {
          borderRadius: 10,
          color: 'rgba(3,169,244, 0.5)',
        },
      },
    ],
  },

  barOptions = {
    title: {

    },
    grid: {
      top: 40,
      x: 25,
      bottom: 40,
    },
    xAxis: {
      data: ['1组', '2组', '3组', '4组', '5组', '6组', '7组', '8组', '9组', '10组', '11组', '12组'],
      axisLabel: {
        // inside: true,
        color: '#fff',
        textStyle: {
          fontSize: 8,
        }
      },
      axisTick: {
        show: false
      },
      axisLine: {
        show: true,
        lineStyle: {
          color: '#8b8b8b',
        }
      },
      splitArea: {
        show: false
      },
      z: 10
    },
    yAxis: {
      name: "（人）",
      axisLine: {
        show: true,
        lineStyle: {
          color: '#8b8b8b'
        }
      },
      axisTick: {
        show: false
      },
      axisLabel: {
        color: '#999',
        textStyle: {
          fontSize: 9
        }
      },
      splitLine: {
        show: false
      },
    },
    series: [
      {
        type: 'bar',
        showBackground: false,
        itemStyle: {
          barBorderRadius: [20, 20, 0, 0],
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            { offset: 0, color: '#83bff6' },
            { offset: 0.5, color: '#188df0' },
            { offset: 1, color: '#188df0' }
          ]),
          emphasis: {
            label: {
              show: true,
              position: 'top',
              color: "#fff"
            }
          }
        },
        data: [328, 393, 285, 346, 276, 427, 246, 192, 229, 97, 393, 95]
      }
    ]
  },
  circleOption = {
    tooltip: {
      trigger: 'item',
      show: false,
    },
    color: ['#4b7aed', '#f45e67', '#c84eff', '#ff6e00'],
    grid: {
      left: '20px'
    },
    legend: {
      right: '5%',
      bottom: '5%',
      itemHeight: 6,
      itemWidth: 8,
      // type: 'plain',
      // width: 130,
      orient: 'vertical',
      textStyle: {
        fontSize: 10,
        color: "#fff",
        padding: [0, 8, 0, 0], // 修改文字和图标距离
      },
    },
    series: [
      {
        name: 'Access From',
        type: 'pie',
        radius: ['45%', '65%'],
        center: ["30%", "50%"],
        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: 10,
          borderColor: '#3a3f6a',
          borderWidth: 2,
        },
        label: {
          normal: {
            show: true,
            position: 'center',
            color: '#fff',
            formatter: '总数' + '\n\r' + '3327',
          },
        },
        emphasis: {
          label: {
            show: false,
            fontSize: 40,
            fontWeight: 'bold'
          }
        },
        labelLine: {
          show: false
        },
        data: [
          { value: 1048, name: '0-15岁' },
          { value: 735, name: '16-60岁' },
          { value: 580, name: '61-65岁' },
          { value: 484, name: '66岁' },
        ]
      }
    ]
  },
  newlineOption = {
    title: { text: '' },
    grid: {
      left: 50,
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: ['2020', '2021', '2022'],
      axisLine: {
        show: true,
        lineStyle: {
          color: '#3a416b'
        }
      },
      axisLabel: {
        textStyle: {
          color: '#a1b3e5',
          fontSize: 8,
        }
      }
    },
    yAxis: {
      type: 'value',
      labelLine: {
        show: false,
      },
      splitLine: {
        show: false,
      },
      axisLine: {
        show: true,
        lineStyle: {
          color: '#a1b3e5'
        }
      },
      axisLabel: {
        textStyle: {
          color: '#a1b3e5',
          fontSize: 8,
        }
      }
    },
    series: [
      {
        data: [],
        type: 'line',
        smooth: 0.6,
        symbol: 'none',
        areaStyle: {
          color: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [  // 渐变颜色
              {
                offset: 0,
                color: '#334fa3',
              },
              {
                offset: 1,
                color: 'rgba(51,79,163, 0)',
              },
            ],
            global: false,
          },
        },
        itemStyle: {
          normal: {
            color: '#4c78ed', //改变折线点的颜色
            lineStyle: {
              color: '#4c78ed' //改变折线颜色
            }
          }
        }
      }
    ]
  },
  mapOption = {
    geo: {
      zoom: 1.1,
      map: 'xiaopingfang', //这里的名称需要和shandong.js: echarts.registerMap('shandong',{})中的名称一致
      roam: false, //缩放
      itemStyle: {
        normal: {
          areaColor: '#2650ac',
          borderColor: '#217cb6',
        },
      },
    },
    series: [{
      type: 'map',
      map: 'xiaopingfang',
      zoom: 1.1,
      label: { // 地图标注
        normal: {
          show: true, // 是否显示对应地名
          textStyle: {
            color: '#d0d9ed'
          }
        },
        emphasis: {   // 鼠标hover显示
          show: true,
          textStyle: {
            color: '#d0d9ed'
          }
        }
      },
      itemStyle: {
        normal: {
          areaColor: '#2650ac',
          shadowColor: '#217cb6',
          shadowBlur: 5,
          shadowOffsetX: 0,
          shadowOffsetY: 5,
          color: '#d0d9ed',
        },
        emphasis: {   // 鼠标hover显示
          show: true,
          areaColor: '#2650ac',
          color: '#d0d9ed',
          textStyle: {
            color: '#d0d9ed'
          }
        }
      }
    }],
  },
  newMapOption = {
    geo: {
      zoom: 1.1,
      map: 'xiaopingfang', //这里的名称需要和shandong.js: echarts.registerMap('shandong',{})中的名称一致
      roam: false, //缩放
      itemStyle: {
        normal: {
          areaColor: '#0075FF',
          borderColor: '#fff',
          borderWidth: 3,
        },
      },

    },
    series: [{
      type: 'map',
      map: 'xiaopingfang',
      zoom: 1.1,
      label: { // 地图标注
        normal: {
          show: true, // 是否显示对应地名
          textStyle: {
            color: '#d0d9ed'
          }
        },
        emphasis: {   // 鼠标hover显示
          show: true,
          textStyle: {
            color: '#d0d9ed'
          }
        }
      },
      itemStyle: {
        normal: {
          areaColor: '#2650ac',
          // borderColor: '#fff',
          // borderWidth: 0.4,
          shadowColor: '#217cb6',
          shadowBlur: 5,
          shadowOffsetX: 0,
          shadowOffsetY: 5,
          color: '#d0d9ed',
        },
        emphasis: {   // 鼠标hover显示
          show: true,
          areaColor: '#2650ac',
          color: '#d0d9ed',
          textStyle: {
            color: '#d0d9ed'
          }
        }
      },

    }],
  }